<template>
  <section class="wrapper bg-light">
    <div class="container py-14 py-md-16">
      <div class="row gy-10 gx-lg-8 gx-xl-12 align-items-center">
        <div class="col-lg-7 order-lg-2 position-relative">
          <figure><img class="w-auto" src="@/assets/img/concept/concept14.png" srcset="@/assets/img/concept/concept14@2x.png 2x" alt="" /></figure>
        </div>
        <!--/column -->
        <div class="col-lg-5">
          <h2 class="display-4 mb-3">Let’s Talk</h2>
          <p class="lead fs-lg">Let's make something great together. We are <span class="underline purple">trusted by</span> over 5000+ clients. Join them by using our services and grow your business.</p>
          <p>Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas faucibus mollis interdum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.</p>
          <a href="#" class="btn btn-purple rounded-pill mt-2">Join Us</a>
        </div>
        <!--/column -->
      </div>
      <!--/.row -->
    </div>
    <!-- /.container -->
  </section>
  <!-- /section -->
</template>


<script>
const eurocurrency = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2
});


export default {
  name: 'contact',
  components: {},
  props: {},
  data: () => ({}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
