<template>
<section class="wrapper bg-light">
	<div class="container py-7 py-md-8">
		<div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
			<div class="col-md-8 col-lg-6 col-xl-5 order-lg-2 position-relative">
				<div class="shape bg-soft-primary rounded-circle rellax w-20 h-20"
						 data-rellax-speed="1"
						 style="top: -2rem; right: -1.9rem;"></div>
				<figure class="rounded"><img src="@/assets/media/business-model.jpg"
							 alt=""></figure>
			</div>
			<!--/column -->
			<div class="col-lg-6">
				<h2 class="display-4 mb-3"
						v-html="title" />
				<p class="lead fs-lg"
					 v-html="subtitle" />
				<p class="mb-6"
					 v-html="text" />
				<div class="row gx-xl-10 gy-6">
					<div class="col-md-6">
						<div class="d-flex flex-row">

							<div>
								<h4 class="mb-1"
										v-html="h1" />
								<p class="mb-0"
									 v-html="p1" />
							</div>
						</div>
					</div>
					<!--/column -->
					<div class="col-md-6">
						<div class="d-flex flex-row">

							<div>
								<h4 class="mb-1"
										v-html="h2" />
								<p class="mb-0"
									 v-html="p2" />
							</div>
						</div>
					</div>
					<!--/column -->
				</div>
				<!--/.row -->
			</div>
			<!--/column -->
		</div>
		<!--/.row -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'about1',
	components: {},
	props: {
		title: String,
		subtitle: String,
		text: String,
		h1: String,
		p1: String,
		h2: String,
		p2: String
	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
