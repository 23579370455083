<template>
<div>
	<section class="wrapper bg-soft-primary">
		<div class="container pt-10 pb-15 pt-md-14 pb-md-20">
			<div class="row gx-lg-8 gx-xl-12 gy-10 mb-5 align-items-center">
				<div class="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start order-2 order-lg-0">
					<h1 class="display-1 mb-5 mx-md-n5 mx-lg-0"
							v-html="titre">{{titre}}</h1>
					<p class="lead fs-lg mb-7"
						 v-html="text"></p>
					<div class="d-flex justify-content-center justify-content-lg-start">
						<span><a :href="link1"
								 class="btn btn-primary rounded me-2">{{link_label1}}</a></span>
						<span><a v-if="link2!=''"
								 :href="link2"
								 class="btn btn-yellow rounded">{{link_label2}}</a></span>
					</div>
				</div>
				<!-- /column -->
				<div class="col-lg-7">
					<figure><img class="w-auto  rounded mb-5"
								 :src="img"
								 alt="" /></figure>
				</div>
				<!-- /column -->
			</div>
			<!-- /.row -->
		</div>
		<!-- /.container -->
	</section>
	<!-- /section -->
	<section class="wrapper bg-light">
		<div class="container pt-14 pt-md-16  ">
			<div class="row gx-md-5 gy-5 mt-n18 mt-md-n21 mb-14 mb-md-17">
				<div class="col-md-6 col-xl-3">
					<div class="card shadow-lg"
							 style="height:100%">
						<div class="card-body">
							<img :src="bi1"
									 style="width:100%"
									 alt="" />
							<h4 v-html="bt1">{{bt1}}</h4>
							<p v-html="bx1"
								 class="mb-2"> </p>
							<a style="display:none"
								 href="#"
								 class="more hover link-yellow">En savoir +</a>
						</div>
						<!--/.card-body -->
					</div>
					<!--/.card -->
				</div>
				<!--/column -->
				<div class="col-md-6 col-xl-3">
					<div class="card shadow-lg"
							 style="height:100%">
						<div class="card-body">
							<img :src="bi2"
									 style="width:100%"
									 alt="" />
							<h4 v-html="bt2"> </h4>
							<p v-html="bx2"
								 class="mb-2"> </p>
							<a style="display:none"
								 href="#"
								 class="more hover link-green">En savoir +</a>
						</div>
						<!--/.card-body -->
					</div>
					<!--/.card -->
				</div>
				<!--/column -->
				<div class="col-md-6 col-xl-3">
					<div class="card shadow-lg"
							 style="height:100%">
						<div class="card-body">
							<img :src="bi3"
									 style="width:100%"
									 alt="" />
							<h4 v-html="bt3"> </h4>
							<p v-html="bx3"
								 class="mb-2"> </p>
							<a style="display:none"
								 href="#"
								 class="more hover link-orange">En savoir +</a>
						</div>
						<!--/.card-body -->
					</div>
					<!--/.card -->
				</div>
				<!--/column -->
				<div class="col-md-6 col-xl-3">
					<div class="card shadow-lg"
							 style="height:100%">
						<div class="card-body">
							<img :src="bi4"
									 style="width:100%"
									 alt="" />
							<h4 v-html="bt4"> </h4>
							<p v-html="bx4"
								 class="mb-2"> </p>
							<a style="display:none"
								 href="#"
								 class="more hover link-blue">En savoir +</a>
						</div>
						<!--/.card-body -->
					</div>
					<!--/.card -->
				</div>
				<!--/column -->
			</div>
			<!--/.row -->
		</div>
		<!-- /.container -->
	</section>
	<!-- /section -->
</div>
</template>


<script>
export default {
	name: 'hero2',
	components: {},
	props: {
		titre: String,
		text: String,
		link_label1: String,
		link1: String,
		img: String,
		link_label2: String,
		link2: String,

		li1: String,
		li2: String,
		li3: String,
		li4: String,

		bt1: String,
		bt2: String,
		bt3: String,
		bt4: String,

		bx1: String,
		bx2: String,
		bx3: String,
		bx4: String,

		bi1: String,
		bi2: String,
		bi3: String,
		bi4: String,

	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
