<template>
<section class="wrapper bg-light">
	<div class="container pt-9 pt-lg-11 pb-14 pb-lg-6">
		<div class="row gx-lg-8 gx-xl-10 align-items-center">
			<div class="col-lg-6">
				<figure><img src="@/assets/img/photos/device.png"
							 alt="" /></figure>
			</div>
			<!-- /column -->
			<div class="col-lg-6">
				<h2 class="fs-15 text-uppercase text-muted mb-3"
						v-html="h2" />
				<h3 class="display-4 mb-5"
						v-html="h3" />
				<p class="mb-8 lead"> {{p1}}
					<br />

					<br />{{p2}}
					<br />

				</p>

				<div class="row gy-3 gx-xl-8">
					<div class="col-xl-6">
						<ul class="icon-list bullet-bg bullet-soft-primary mb-0">
							<li><span><i class="uil uil-check"></i></span><span>{{li1}}</span></li>
							<li class="mt-3"><span><i class="uil uil-check"></i></span><span>{{li2}}</span></li>
						</ul>
					</div>
					<div class="col-xl-6">
						<ul class="icon-list bullet-bg bullet-soft-primary mb-0">
							<li><span><i class="uil uil-check"></i></span><span>{{li3}} </span></li>
							<li class="mt-3"><span><i class="uil uil-check"></i></span><span>{{li4}}</span></li>
						</ul>
					</div>
					<!--/column -->
					<div class="col-xl-6">
						<ul class="icon-list bullet-bg bullet-soft-primary mb-0">
							<li><span><i class="uil uil-check"></i></span><span>{{li5}}</span></li>
							<li class="mt-3"><span><i class="uil uil-check"></i></span><span>{{li6}}</span></li>
						</ul>
					</div>

					<div class="col-xl-6">
						<ul class="icon-list bullet-bg bullet-soft-primary mb-0">
							<li><span><i class="uil uil-check"></i></span><span>{{li7}}</span></li>
							<li class="mt-3"><span><i class="uil uil-check"></i></span><span>{{li8}}.</span></li>
						</ul>
					</div>

					<!--/column -->
				</div>
				<!--/.row -->
				<div class="d-flex justify-content-center justify-content-lg-start mt-5">
					<span><a :href="link1"
							 class="btn btn-primary rounded me-2">{{link_label1}}</a></span>
					<span><a v-if="link_label2!=''"
							 :href="link2"
							 class="btn btn-yellow rounded">{{link_label2}}</a></span>
				</div>


			</div>
			<!-- /column -->
		</div>
		<!-- /.row -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'process1',
	components: {},
	props: {
		link_label1: String,
		link_label2: String,
		link1: String,
		link2: String,
		h2: String,
		h3: String,
		p1: String,
		p2: String,
		li1: String,
		li2: String,
		li3: String,
		li4: String,
		li5: String,
		li6: String,
		li7: String,
		li8: String

	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
