<template>
<section class="wrapper bg-light">
	<div class="container py-7 py-md-8">
		<div class="row">
			<div class="col-md-10 offset-md-1 col-lg-12 offset-lg-2 mx-auto text-center">
				<h2 class="fs-15 text-uppercase text-muted mb-3">{{accroche}}</h2>
				<h3 class="display-4 mb-10 px-xl-10 px-xxl-15">{{titre}}</h3>
			</div>
			<!-- /column -->
		</div>
		<!-- /.row -->
		<div class="grid">
			<div class="row isotope gy-6">
				<div class="item col-md-6 col-xl-4">
					<div class="card"
							 style="height:100%">
						<div class="card-body">
							<span class="ratings five mb-3"></span>
							<blockquote class="icon mb-0">
								<p><b>“{{a1}}”</b></p>
								<p>{{t1}}</p>
								<div class="blockquote-details">
									<img class="rounded-circle w-12"
											 :src="avatar1"
											 alt="" />
									<div class="info">
										<h5 class="mb-1">{{nom1}}</h5>
										<p class="mb-0">{{profession1}}</p>
									</div>
								</div>
							</blockquote>
						</div>
						<!-- /.card-body -->
					</div>
					<!-- /.card -->
				</div>
				<!--/column -->

				<!--/column -->

				<!--/column -->

				<!--/column -->
				<div class="item col-md-6 col-xl-4">
					<div class="card"
							 style="height:100%">
						<div class="card-body">
							<span class="ratings five mb-3"></span>
							<blockquote class="icon mb-0">
								<p><b>“{{a2}}”</b></p>
								<p>{{t2}}</p>
								<div class="blockquote-details">
									<img class="rounded-circle w-12"
											 :src="avatar2"
											 alt="" />
									<div class="info">
										<h5 class="mb-1">{{nom2}}</h5>
										<p class="mb-0">{{profession2}}</p>
									</div>
								</div>
							</blockquote>
						</div>
						<!-- /.card-body -->
					</div>
					<!-- /.card -->
				</div>
				<!--/column -->
				<div class="item col-md-6 col-xl-4">
					<div class="card"
							 style="height:100%">
						<div class="card-body">
							<span class="ratings five mb-3"></span>
							<blockquote class="icon mb-0">
								<p><b>“{{a3}}”</b></p>
								<p>{{t3}}</p>
								<div class="blockquote-details">
									<img class="rounded-circle w-12"
											 :src="avatar3"
											 alt="" />
									<div class="info">
										<h5 class="mb-1">{{nom3}}</h5>
										<p class="mb-0">{{profession3}}</p>
									</div>
								</div>
							</blockquote>
						</div>
						<!-- /.card-body -->
					</div>
					<!-- /.card -->
				</div>
				<!--/column -->
			</div>
			<!-- /.row -->
		</div>
		<!-- /.grid-view -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'testimonial1',
	components: {},
	props: {
		accroche: String,
		titre: String,
		a1: String,
		t1: String,
		nom1: String,
		profession1: String,
		a2: String,
		t2: String,
		nom2: String,
		profession2: String,
		a3: String,
		t3: String,
		nom3: String,
		profession3: String,
		avatar3: String,
		avatar2: String,
		avatar1: String,
	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
