<template>
<div class="home">

	<hero2 :titre="$t('home_hero2_title')"
				 :text="$t('home_hero2_text') "
				 img="https://media.berlcoin.fr/4288663.png"
				 :link_label1="$t('label_invest')"
				 link_label2=""
				 :link1="$t('link_newinvest')"
				 link2=""
				 :bt1="$t('home_hero2_bt1')"
				 :bt2="$t('home_hero2_bt2')"
				 :bt3="$t('home_hero2_bt3')"
				 :bt4="$t('home_hero2_bt4')"
				 :bx1="$t('home_hero2_bx1')"
				 :bx2="$t('home_hero2_bx2')"
				 :bx3="$t('home_hero2_bx3')"
				 :bx4="$t('home_hero2_bx4')"
				 bi2="https://media.berlcoin.fr/3.png"
				 bi1="https://media.berlcoin.fr/2.png"
				 bi3="https://media.berlcoin.fr/1.png"
				 bi4="https://media.berlcoin.fr/4.png" />

	<action2 :titre="'<b style=\'font-size:350%; text-align:center;width:100%\'>6.1%</b><br/>'+$t('action2_titre')+' <br/> ' "
					 soustitre=""
					 :link_label="$t('label_invest')"
					 :link="$t('link_newinvest')" />

	<hero1 :accroche="$t('hero1_accroche')"
				 titre="BERLCOIN"
				 :text="$t('hero1_text')"
				 img="https://media.berlcoin.fr/concept5.png"
				 :link_label1="$t('label_invest')"
				 link_label2=""
				 :link1="$t('link_newinvest')"
				 link2="" />

	<action1 :titre="$t('action1_titre')"
					 :soustitre="$t('action1_soustitre')"
					 :link_label="$t('action1_link_label')"
					 :link="$t('action1_link')" />

	<feature2 :accroche="$t('feature2_accroche')"
						:titre="$t('feature2_titre')"
						:lead="$t('feature2_lead')"
						:text="$t('feature2_text')"
						:t1="$t('feature2_t1')"
						:p1="$t('feature2_p1')"
						k1=""
						l1=""
						img1="https://media.berlcoin.fr/f1.jpg"
						:t2="$t('feature2_t2')"
						:p2="$t('feature2_p2')"
						k2=""
						l2=""
						img2="https://media.berlcoin.fr/f2.jpg"
						:t3="$t('feature2_t3')"
						:p3="$t('feature2_p3')"
						k3=""
						l3=""
						img3="https://media.berlcoin.fr/f3.jpg"
						:t4="$t('feature2_t4')"
						:p4="$t('feature2_p4')"
						k4=""
						l4=""
						img4="https://media.berlcoin.fr/f4.jpg"
						color="soft-green" />


	<feature1 accroche=""
						titre=""
						lead=""
						text=""
						:t1="$t('feature1_t1')"
						:p1="$t('feature1_p1')"
						k1=""
						l1=""
						img1="uil-home"
						:t2="$t('feature1_t2')"
						:p2="$t('feature1_p2')"
						k2=""
						l2=""
						img2="uil-calculator-alt"
						:t3="$t('feature1_t3')"
						:p3="$t('feature1_p3')"
						k3=""
						l3=""
						img3="uil-circle-layer"
						:t4="$t('feature1_t4')"
						:p4="$t('feature1_p4')"
						k4=""
						l4=""
						img4="uil-mobile-android"
						color="soft-green" />


	<process3 :t1="$t('process3_t1')"
						:tx1="$t('process3_tx1')"
						:t2="$t('process3_t2')"
						:tx2="$t('process3_tx2')"
						:t3="$t('process3_t3')"
						:tx3="$t('process3_tx3')"
						:titre="$t('process3_titre')"
						:soustitre="$t('process3_soustitre')"
						:lead="$t('process3_lead')"
						:soustitre2="$t('process3_lead')"
						:lead2="$t('process3_lead2')"
						:it1="$t('process3_it1')"
						:id1="$t('process3_id1')"
						:it2="$t('process3_it2')"
						:id2="$t('process3_id2')"
						:it3="$t('process3_it3')"
						:id3="$t('process3_id3')" />

	<action1 :titre="$t('action1_titre')"
					 :soustitre="$t('action1_soustitre')"
					 :link_label="$t('action1_link_label')"
					 :link="$t('action1_link')" />

	<process4 :titre="$t('process4_titre')"
						:lead="$t('process4_lead')"
						:it1="$t('process4_it1')"
						:id1="$t('process4_id1')"
						:it2="$t('process4_it2')"
						:id2="$t('process4_id2')"
						:it3="$t('process4_it3')"
						:id3="$t('process4_id3')"
						:it4="$t('process4_it4')"
						:id4="$t('process4_id4')" />

	<feature3 :h2="$t('feature3_h2')"
						:h3="$t('feature3_h3')"
						:h4_1="$t('feature3_h4_1')"
						:p_1="$t('feature3_p_1')"
						:h4_2="$t('feature3_h4_2')"
						:p_2="$t('feature3_p_2')"
						:h4_3="$t('feature3_h4_3')"
						:p_3="$t('feature3_p_3')"
						:h4_4="$t('feature3_h4_4')"
						:p_4="$t('feature3_p_4')"
						:h4_5="$t('feature3_h4_5')"
						:p_5="$t('feature3_p_5')"
						:h4_6="$t('feature3_h4_6')"
						:p_6="$t('feature3_p_6')"
						:h4_7="$t('feature3_h4_7')"
						:p_7="$t('feature3_p_7')"
						:h4_8="$t('feature3_h4_8')"
						:p_8="$t('feature3_p_8')"
						:h4_9="$t('feature3_h4_9')"
						:p_9="$t('feature3_p_9')" />

	<process1 :h2="$t('process1_h2')"
						:h3="$t('process1_h3')"
						:p1="$t('process1_p1')"
						:p2="$t('process1_p2')"
						:li1="$t('process1_li1')"
						:li2="$t('process1_li2')"
						:li3="$t('process1_li3')"
						:li4="$t('process1_li4')"
						:li5="$t('process1_li5')"
						:li6="$t('process1_li6')"
						:li7="$t('process1_li7')"
						:li8="$t('process1_li8')"
						:link1="$t('link_newinvest')"
						:link_label1="$t('label_invest')"
						link_label2="" />

	<action1 :titre="$t('action1_titre')"
					 :soustitre="$t('action1_soustitre')"
					 :link_label="$t('action1_link_label')"
					 :link="$t('action1_link')" />

	<team1 :accroche="$t('team1_accroche')"
				 :titre="$t('team1_titre')"
				 text=" "
				 :link="$t('link_contact')"
				 :link_label="$t('label_contact')"
				 img1="https://coffee.berlcoin.berlin/assets/img/berlcoin/laurent.png"
				 :nom1="$t('team1_nom1')"
				 metier1="CEO"
				 present1=""
				 img2="https://coffee.berlcoin.berlin/assets/img/berlcoin/laure.png"
				 :nom2="$t('team1_nom2')"
				 :metier2="$t('team1_metier2')"
				 present2=""
				 img3="https://coffee.berlcoin.berlin/assets/img/berlcoin/manu.png"
				 :nom3="$t('team1_nom3')"
				 :metier3="$t('team1_metier3')"
				 present3=""
				 img4="https://coffee.berlcoin.berlin/assets/img/berlcoin/angeline.png"
				 :nom4="$t('team1_nom4')"
				 :metier4="$t('team1_metier4')"
				 present4=""
				 img5="https://coffee.berlcoin.berlin/assets/img/berlcoin/jade.png"
				 :nom5="$t('team1_nom5')"
				 :metier5="$t('team1_metier5')"
				 present5="" />

	<testimonial1 :accroche="$t('testimonial1_accroche')"
								:titre="$t('testimonial1_titre')"
								:a1="$t('testimonial1_a1')"
								:t1="$t('testimonial1_t1')"
								nom1="Paul Lemoire"
								:profession1="$t('testimonial1_profession1')"
								:a2="$t('testimonial1_a2')"
								:t2="$t('testimonial1_t2')"
								nom2="Jeanne Blacat"
								:profession2="$t('testimonial1_profession2')"
								:a3="$t('testimonial1_a3')"
								:t3="$t('testimonial1_t3')"
								nom3="Elise Deloime"
								:profession3="$t('testimonial1_profession3')"
								avatar1="https://media.berlcoin.fr/avatars/2004.jpg"
								avatar2="https://media.berlcoin.fr/avatars/2031.jpg"
								avatar3="https://media.berlcoin.fr/avatars/2138.jpg" />


	<action1 :titre="$t('action1_titre')"
					 :soustitre="$t('action1_soustitre')"
					 :link_label="$t('action1_link_label')"
					 :link="$t('action1_link')" />


	<testimonial2 :p1="$t('testimonial2_p1')"
								:h5_1="$t('testimonial2_h5_1')"
								:p2="$t('testimonial2_p2')"
								:h5_2="$t('testimonial2_h5_2')"
								:p3="$t('testimonial2_p3')"
								:h5_3="$t('testimonial2_h5_3')"
								:p4="$t('testimonial2_p4')"
								:h5_4="$t('testimonial2_h5_4')"
								:h2="$t('testimonial2_h2')"
								:t1="$t('testimonial2_t1')"
								:t2="$t('testimonial2_t2')"
								:a="$t('label_question')"
								:link="$t('link_question')" />



	<price1 :t1="$t('price1_t1')"
					:st1="$t('price1_st1')"
					:t2="$t('price1_t2')"
					:st2="$t('price1_st2')"
					:t3="$t('price1_t3')"
					:st3="$t('price1_st3')"
					:t4="$t('price1_t4')"
					:st4="$t('price1_st4')"
					:a="$t('label_invest')"
					:link="$t('link_offer')" />


	<about1 :title="$t('about1_title')"
					:subtitle="$t('about1_subtitle')"
					:text="$t('about1_text')"
					:h1="$t('about1_h1')"
					:p1="$t('about1_p1')"
					:h2="$t('about1_h2')"
					:p2="$t('about1_p2')" />

	<fact3 :home_sponsor1="$t('home_sponsor_1')"
				 :home_sponsor2="$t('home_sponsor_2')"
				 :home_sponsor3="$t('home_sponsor_3')"
				 :home_sponsor4="$t('home_sponsor_4')"
				 :home_sponsor5="$t('home_sponsor_5')"
				 :home_sponsor6="$t('label_decouvrir')"
				 :link="'/'+$i18n.locale+'/sponsoring'" />

	<feature1 :accroche="$t('feature1_accroche')"
						:titre="$t('feature1_titre')"
						:t1="$t('feature1_t1')"
						:p1="$t('feature1_p1')"
						k1=""
						l1=""
						img1="uil-brightness-empty"
						:t2="$t('feature1_t2')"
						:p2="$t('feature1_p2')"
						k2=""
						l2=""
						img2="uil-calculator-alt"
						:t3="$t('feature1_t3')"
						:p3="$t('feature1_p3')"
						k3=""
						l3=""
						img3="uil-shield"
						:t4="$t('feature1_t4')"
						:p4="$t('feature1_p4')"
						k4=""
						l4=""
						img4="uil-chart-line" />


	<testimonial3 :citation="$t('testimonial3_citation')"
								:fin="$t('testimonial3_fin')" />



	<action1 :titre="$t('action1_titre')"
					 :soustitre="$t('action1_soustitre')"
					 :link_label="$t('action1_link_label')"
					 :link="$t('action1_link')" />





</div>
</template>

<script>
// @ is an alias to /src

import contact from "@/components/contact.vue";
import hero1 from "@/components/hero1.vue";
import hero2 from "@/components/hero2.vue";


import process1 from "@/components/process1.vue";

import process3 from "@/components/process3.vue";
import process4 from "@/components/process5.vue";

import team1 from "@/components/team1.vue";

import testimonial1 from "@/components/testimonial1.vue";
import testimonial2 from "@/components/testimonial2.vue";
import testimonial3 from "@/components/testimonial3.vue";
import price1 from "@/components/price1.vue";
import price2 from "@/components/price2.vue";
import action1 from "@/components/action1.vue";
import footer2 from "@/components/footer2.vue";
import nav1 from "@/components/nav1.vue";
import feature1 from "@/components/feature1.vue";
import feature2 from "@/components/feature2.vue";
import feature3 from "@/components/feature3.vue";

import about1 from "@/components/about1.vue";

import action2 from "@/components/action2.vue";
import fact3 from "@/components/fact3.vue";

export default {
	name: 'Home',
	components: {
		about1,
		contact,
		hero1,
		hero2,
		process1,
		process3,
		process4,
		team1,
		testimonial1,
		testimonial2,
		testimonial3,
		price1,
		price2,
		action1,
		footer2,
		nav1,
		feature1,
		feature2,
		feature3,
		action2,
		fact3
	},
	props: {

	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		if (this.$route.params.lang != undefined) this.$i18n.locale = this.$route.params.lang
		//	console.log(this.$route.params.lang)
		//	console.log(this.$i18n.locale)
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {

	}
}
</script>

<style scoped>
</style>
