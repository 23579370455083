<template>
<section class="wrapper bg-light">

	<div class="row">
		<div class="col-xl-12">
			<div class="card image-wrapper bg-full bg-image  ">
				<div style="background : #00695C;border-radius:10px"
						 v-html="titre"
						 class="card-body text-white h1  text-center ">

					<!--/.card-body -->
				</div>
			</div>
			<!--/.card -->
		</div>
		<!-- /column -->
	</div>
	<!-- /.row -->

	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'action1',
	components: {},
	props: {
		titre: String,
		soustitre: String,
		link: String,
		link_label: String
	},
	data: () => ({


	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
