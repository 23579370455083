<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16 pt-md-20">
		<div class="card bg-soft-primary">
			<div class="card-body p-md-10 py-xxl-16 position-relative">
				<div class="position-absolute d-none d-lg-block"
						 style="bottom:0; left:10%; width: 28%; z-index:2">
					<figure><img src="@/assets/img/photos/woman.png"
								 alt=""></figure>
				</div>
				<div class="row gx-md-0 gx-xl-12 text-center">
					<div class="col-lg-7 offset-lg-5 col-xl-6">

						<blockquote class="border-0 fs-lg mb-0">
							<p style="display:none">“Toute personne qui investit dans un bien immobilier attentivement sélectionné, dans un quartier en croissance d’une ville prospère, adopte la méthode la plus sûre pour devenir indépendant financièrement, parce
								que l’immobilier est à la base de la richesse.”</p>
							<p style="display:none">Ce qui est encore plus important que la constitution d’un patrimoine, c’est de le faire d’une manière qui vous apportera la tranquillité d’esprit. </p>
							<p style="display:none">Price is what you pay, value is what you get</p>
							<p style="display:none"> Les investisseurs doivent toujours garder à l’esprit que la mesure la plus importante n’est pas les rendements réalisés, mais les rendements pondérés par rapport aux risques encourus.</p>
							<p v-html="citation" />
							<div class="blockquote-details justify-content-center text-center">
								<div class="info p-0">
									<h5 class="mb-1">BERLCOIN</h5>
									<br />
									<div class="meta mb-0"
											 v-html="fin" />
								</div>
							</div>
						</blockquote>
					</div>
					<!-- /column -->
				</div>
				<!-- /.row -->
			</div>
			<!--/.card-body -->
		</div>
		<!--/.card -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'testimonial3',
	components: {},
	props: {
		citation: String,
		fin: String
	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
