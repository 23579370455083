<template>
  <div>
<section class="wrapper bg-gradient-primary">
  <div class="container pt-10 pt-md-14 pb-8 text-center">
    <div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
      <!-- /column -->
      <div class="col-lg-7">
        <figure><img class="w-auto" :src="img"   alt="" /></figure>
      </div>
      <div class="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
        <h2 class="fs-16 text-uppercase text-muted mb-3">{{accroche}}</h2>
        <h1 class="display-1 mb-5 mx-md-n5 mx-lg-0">{{titre}}</h1>
        <p class="lead fs-lg mb-7">{{text}}</p>
        <span><a :href="link1" class="btn btn-primary  me-2">{{link_label1}}</a></span>
        <span v-if='link_label2 != ""' ><a :href="link2" class="btn btn-primary rme-2">{{link_label2}}</a></span>
      </div>
      <!-- /column -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container -->
</section>
<!-- /section -->


</div>
</template>


<script>



export default {
  name: 'hero1',
  components: {},
  props: {
    titre:String,
    text:String,
    link_label1:String,
    link1:String,
    img:String,
    link_label2:String,
    link2:String,
    accroche:String,
  },
  data: () => ({}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
