<template>
  <section class="wrapper bg-light">
    <div class="container py-14 py-md-16">
      <div class="row gy-6">
        <div class="col-lg-4">
          <h2 class="display-4 mt-lg-18 mb-3">Our Pricing</h2>
          <p class="lead fs-lg">We offer <span class="underline">great prices</span>, premium and quality products for your business.</p>
          <p>Enjoy a <a href="#" class="hover">free 30-day trial</a> and experience the full service. No credit card required!</p>
          <a href="#" class="btn btn-primary rounded-pill mt-2">See All Prices</a>
        </div>
        <!--/column -->
        <div class="col-lg-7 offset-lg-1 pricing-wrapper">
          <div class="pricing-switcher-wrapper switcher justify-content-start justify-content-lg-end">
            <p class="mb-0 pe-3">Monthly</p>
            <div class="pricing-switchers">
              <div class="pricing-switcher pricing-switcher-active"></div>
              <div class="pricing-switcher"></div>
              <div class="switcher-button bg-primary"></div>
            </div>
            <p class="mb-0 ps-3">Yearly <span class="text-red">(Save 30%)</span></p>
          </div>
          <div class="row gy-6 position-relative mt-5">
            <div class="shape bg-dot primary rellax w-16 h-18" data-rellax-speed="1" style="bottom: -0.5rem; right: -1.6rem;"></div>
            <div class="shape rounded-circle bg-line red rellax w-18 h-18" data-rellax-speed="1" style="top: -1rem; left: -2rem;"></div>
            <div class="col-md-6">
              <div class="pricing card">
                <div class="card-body pb-12">
                  <div class="prices text-dark">
                    <div class="price price-show"><span class="price-currency">$</span><span class="price-value">19</span> <span class="price-duration">month</span></div>
                    <div class="price price-hide price-hidden"><span class="price-currency">$</span><span class="price-value">199</span> <span class="price-duration">year</span></div>
                  </div>
                  <!--/.prices -->
                  <h4 class="card-title mt-2">Premium Plan</h4>
                  <ul class="icon-list bullet-bg bullet-soft-primary mt-8 mb-9">
                    <li><i class="uil uil-check"></i><span><strong>5</strong> Projects </span></li>
                    <li><i class="uil uil-check"></i><span><strong>100K</strong> API Access </span></li>
                    <li><i class="uil uil-check"></i><span><strong>200MB</strong> Storage </span></li>
                    <li><i class="uil uil-check"></i><span> Weekly <strong>Reports</strong></span></li>
                    <li><i class="uil uil-times bullet-soft-red"></i><span> 7/24 <strong>Support</strong></span></li>
                  </ul>
                  <a href="#" class="btn btn-primary rounded-pill">Choose Plan</a>
                </div>
                <!--/.card-body -->
              </div>
              <!--/.pricing -->
            </div>
            <!--/column -->
            <div class="col-md-6 popular">
              <div class="pricing card">
                <div class="card-body pb-12">
                  <div class="prices text-dark">
                    <div class="price price-show"><span class="price-currency">$</span><span class="price-value">49</span> <span class="price-duration">month</span></div>
                    <div class="price price-hide price-hidden"><span class="price-currency">$</span><span class="price-value">499</span> <span class="price-duration">year</span></div>
                  </div>
                  <!--/.prices -->
                  <h4 class="card-title mt-2">Corporate Plan</h4>
                  <ul class="icon-list bullet-bg bullet-soft-primary mt-8 mb-9">
                    <li><i class="uil uil-check"></i><span><strong>20</strong> Projects </span></li>
                    <li><i class="uil uil-check"></i><span><strong>300K</strong> API Access </span></li>
                    <li><i class="uil uil-check"></i><span><strong>500MB</strong> Storage </span></li>
                    <li><i class="uil uil-check"></i><span> Weekly <strong>Reports</strong></span></li>
                    <li><i class="uil uil-check"></i><span> 7/24 <strong>Support</strong></span></li>
                  </ul>
                  <a href="#" class="btn btn-primary rounded-pill">Choose Plan</a>
                </div>
                <!--/.card-body -->
              </div>
              <!--/.pricing -->
            </div>
            <!--/column -->
          </div>
          <!--/.row -->
        </div>
        <!--/column -->
      </div>
      <!--/.row -->
    </div>
    <!-- /.container -->
  </section>
  <!-- /section -->
</template>


<script>

export default {
  name: 'account',
  components: {},
  props: {},
  data: () => ({}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
