<template>
<section class="wrapper bg-light">
	<div class="container py-7 py-md-8">
		<div class="row text-center">
			<div class="">
				<h2 class="fs-15 text-uppercase text-muted mb-3"
						v-html="h2" />
				<h3 class="display-4 mb-10 px-xl-11"
						v-html="h3" />
			</div>
			<!-- /column -->
		</div>
		<!-- /.row -->
		<div class="row gx-lg-8 gx-xl-12 gy-8">
			<!--/column -->
			<div class="col-md-6 col-lg-4">
				<div class="d-flex flex-row">
					<div>
						<img src="@/assets/media/svg/resize-down.svg"
								 class="svg-inject icon-svg icon-svg-sm text-red me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green"
								v-html="h4_1" />
						<p class="mb-0"
							 v-html="p_1" />
					</div>
				</div>
			</div>
			<!--/column -->
			<div class="col-md-6 col-lg-4">
				<div class="d-flex flex-row">
					<div>

						<img src="@/assets/media/svg/location.svg"
								 class="svg-inject icon-svg icon-svg-sm text-red me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green"
								v-html="h4_2" />
						<p class="mb-0"
							 v-html="p_2" />
					</div>
				</div>
			</div>
			<!--/column -->
			<div class="col-md-6 col-lg-4">
				<div class="d-flex flex-row">
					<div>
						<img src="@/assets/media/svg/note.svg"
								 class="svg-inject icon-svg icon-svg-sm text-green me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green"
								v-html="h4_3" />
						<p class="mb-0"
							 v-html="p_3" />
					</div>
				</div>
			</div>
			<!--/column -->
			<div class="col-md-6 col-lg-4"
					 v-if="false">
				<div class="d-flex flex-row">
					<div>
						<img src="@/assets/media/svg/bank.svg"
								 class="svg-inject icon-svg icon-svg-sm text-green me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green"
								v-html="h4_4" />
						<p class="mb-0"
							 v-html="p_4" />
					</div>
				</div>
			</div>
			<!--/column -->
			<div class="col-md-6 col-lg-4"
					 v-if="false">
				<div class="d-flex flex-row">
					<div>
						<img src="@/assets/media/svg/home.svg"
								 class="svg-inject icon-svg icon-svg-sm text-aqua me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green"
								v-html="h4_5" />
						<p class="mb-0"
							 v-html="p_5" />
					</div>
				</div>
			</div>
			<!--/column -->
			<div class="col-md-6 col-lg-4">
				<div class="d-flex flex-row">
					<div>
						<img src="@/assets/media/svg/link.svg"
								 class="svg-inject icon-svg icon-svg-sm text-green me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green"
								v-html="h4_6" />
						<p class="mb-0"
							 v-html="p_6" />
					</div>
				</div>
			</div>
			<!--/column -->
			<div class="col-md-6 col-lg-4">
				<div class="d-flex flex-row">
					<div>
						<img src="@/assets/media/svg/shield.svg"
								 class="svg-inject icon-svg icon-svg-sm text-purple me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green"
								v-html="h4_7" />
						<p class="mb-0"
							 v-html="p_7" />
					</div>
				</div>
			</div>
			<!--/column -->
			<div class="col-md-6 col-lg-4"
					 v-if="false">
				<div class="d-flex flex-row">
					<div>
						<img src="@/assets/media/svg/money-exchange.svg"
								 class="svg-inject icon-svg icon-svg-sm text-purple me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green"
								v-html="h4_8" />
						<p class="mb-0"
							 v-html="p_8" />
					</div>
				</div>
			</div>
			<!--/column -->
			<div class="col-md-6 col-lg-4">
				<div class="d-flex flex-row">
					<div>
						<img src="@/assets/media/svg/chart.svg"
								 class="svg-inject icon-svg icon-svg-sm text-purple me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green"
								v-html="h4_9" />
						<p class="mb-0"
							 v-html="p_9" />
					</div>
				</div>
			</div>
		</div>
		<!--/.row -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'feature3',
	components: {},
	props: {
		h2: String,
		h3: String,
		h4_1: String,
		p_1: String,
		h4_2: String,
		p_2: String,
		h4_3: String,
		p_3: String,
		h4_4: String,
		p_4: String,
		h4_5: String,
		p_5: String,
		h4_6: String,
		p_6: String,
		h4_7: String,
		p_7: String,
		h4_8: String,
		p_8: String,
		h4_9: String,
		p_9: String,
	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
