<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row">
			<div class="col-lg-10 col-xl-9 col-xxl-8 mx-auto text-center">
				<h2 class="fs-15 text-uppercase text-muted mb-3"
						v-html="t1" />
				<h4 class="display-7 mb-15 mb-md-6 px-lg-10"
						v-html="st1" />
			</div>
			<!--/column -->
		</div>
		<!--/.row -->
		<div class="pricing-wrapper position-relative">
			<div class="shape bg-dot primary rellax w-16 h-18"
					 data-rellax-speed="1"
					 style="top: 2rem; right: -2.4rem;"></div>
			<div class="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
					 data-rellax-speed="1"
					 style="bottom: 0.5rem; left: -2.5rem;"></div>

			<div class="row gy-6 mt-3 mt-md-5">
				<div class="col-md-6 col-lg-4">
					<div class="pricing card text-center">
						<div class="card-body">
							<img src="@/assets/img/icons/calculator.svg"
									 class="svg-inject icon-svg icon-svg-md text-primary mb-3"
									 alt="" />
							<h4 class="card-title"
									v-html="t2" />
							<p v-html="st2" />

							<!--/.prices -->
							<ul class="icon-list bullet-bg bullet-soft-primary mt-8 mb-9 text-start">

								<div v-if="false">
									<li><i class="uil uil-check bullet-soft-green"></i>Plus value latente</li>
									<li><i class="uil uil-check bullet-soft-green"></i>Revenu locatif
									<li><i class="uil uil-times bullet-soft-red"></i>Redevance</li>
									<li><i class="uil uil-times bullet-soft-red"></i>Prix d'achat garantie</li>
									<li><i class="uil uil-times bullet-soft-red"></i>Effet de levier bancaire</li>
									<li><i class="uil uil-times bullet-soft-red"></i>Droit de préemption</li>
								</div>
							</ul>
							<a :href="link"
								 class="btn btn-primary  "
								 v-html="a" />
						</div>

						<!--/.card-body -->
					</div>
					<!--/.pricing -->
				</div>
				<!--/column -->
				<div class="col-md-6 col-lg-4 popular">
					<div class="pricing card text-center">
						<div class="card-body">
							<img src="@/assets/img/icons/pie-chart-2.svg"
									 class="svg-inject icon-svg icon-svg-md text-primary mb-3"
									 alt="" />
							<h4 class="card-title"
									v-html="t3" />
							<p v-html="st3" />
							<!--/.prices -->
							<ul class="icon-list bullet-bg bullet-soft-primary mt-8 mb-9 text-start">
								<div v-if="false">
									<li><i class="uil uil-check bullet-soft-green"></i>Plus value latente</li>
									<li><i class="uil uil-check bullet-soft-green"></i>Revenu locatif
									<li><i class="uil uil-check bullet-soft-green"></i>Redevance</li>
									<li><i class="uil uil-check bullet-soft-green"></i>Prix d'achat garantie</li>
									<li><i class="uil uil-arrows-h-alt bullet-soft-purple"></i>Effet de levier bancaire</li>
									<li><i class="uil uil-times bullet-soft-red"></i>Droit de préemption</li>
								</div>
							</ul>
							<a :href="link"
								 class="btn btn-primary  "
								 v-html="a" />
						</div>
						<!--/.card-body -->
					</div>
					<!--/.pricing -->
				</div>
				<!--/column -->
				<div class="col-md-6 offset-md-3 col-lg-4 offset-lg-0">
					<div class="pricing card text-center">
						<div class="card-body">
							<img src="@/assets/img/icons/home.svg"
									 class="svg-inject icon-svg icon-svg-md text-primary mb-3"
									 alt="" />
							<h4 class="card-title"
									v-html="t4" />
							<p v-html="st4" />

							<!--/.prices -->
							<ul class="icon-list bullet-bg bullet-soft-primary mt-8 mb-9 text-start">
								<div v-if="false">
									<li><i class="uil uil-check bullet-soft-green"></i>Plus value latente</li>
									<li><i class="uil uil-check bullet-soft-green"></i>Revenu locatif
									<li><i class="uil uil-check bullet-soft-green"></i>Redevance</li>
									<li><i class="uil uil-times bullet-soft-green"></i>Prix d'achat garantie</li>
									<li><i class="uil uil-times bullet-soft-green"></i>Effet de levier bancaire</li>
									<li><i class="uil uil-times bullet-soft-green"></i>Droit de préemption</li>
								</div>
							</ul>
							<a :href="link"
								 class="btn btn-primary "
								 v-html="a" />
						</div>
						<!--/.card-body -->
					</div>
					<!--/.pricing -->
				</div>
				<!--/column -->
			</div>
			<!--/.row -->
		</div>
		<!--/.pricing-wrapper -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'price1',
	components: {},
	props: {
		t1: String,
		st1: String,
		t2: String,
		st2: String,
		t3: String,
		st3: String,
		t4: String,
		st4: String,
		a: String,
		link: String,
	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
