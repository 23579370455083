<template>
<section class="wrapper bg-light">
	<div class="container py-7 py-md-8">
		<div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
			<div class="col-lg-7">
				<div class="row gx-md-5 gy-5">
					<div class="col-md-6 col-xl-5 align-self-end">
						<div class="card bg-pale-yellow">
							<div class="card-body">
								<blockquote class="icon mb-0">
									<p>“{{p1}}”</p>
									<div class="blockquote-details">
										<div class="info p-0">
											<h5 class="mb-1"
													v-html="h5_1" />

										</div>
									</div>
								</blockquote>
							</div>
							<!--/.card-body -->
						</div>
						<!--/.card -->
					</div>
					<!--/column -->
					<div class="col-md-6 align-self-end">
						<div class="card bg-pale-red">
							<div class="card-body">
								<blockquote class="icon mb-0">
									<p>“{{p2}}”</p>
									<div class="blockquote-details">
										<div class="info p-0">
											<h5 class="mb-1"
													v-html="h5_2" />

										</div>
									</div>
								</blockquote>
							</div>
							<!--/.card-body -->
						</div>
						<!--/.card -->
					</div>
					<!--/column -->
					<div class="col-md-6 col-xl-5 offset-xl-1">
						<div class="card bg-pale-leaf">
							<div class="card-body">
								<blockquote class="icon mb-0">
									<p>“{{p3}}”</p>
									<div class="blockquote-details">
										<div class="info p-0">
											<h5 class="mb-1"
													v-html="h5_3" />

										</div>
									</div>
								</blockquote>
							</div>
							<!--/.card-body -->
						</div>
						<!--/.card -->
					</div>
					<!--/column -->
					<div class="col-md-6 align-self-start">
						<div class="card bg-pale-blue">
							<div class="card-body">
								<blockquote class="icon mb-0">
									<p>“{{p4}}”</p>
									<div class="blockquote-details">
										<div class="info p-0">
											<h5 class="mb-1"
													v-html="h5_4" />

										</div>
									</div>
								</blockquote>
							</div>
							<!--/.card-body -->
						</div>
						<!--/.card -->
					</div>
					<!--/column -->
				</div>
				<!--/.row -->
			</div>
			<!--/column -->
			<div class="col-lg-5">
				<h2 class="display-4 mb-3"
						v-html="h2" />
				<p class="lead fs-lg"
					 v-html="t1" />
				<p v-html="t2" />
				<a :href="link"
					 class="btn btn-navy mt-3"
					 v-html="a" />
			</div>
			<!--/column -->
		</div>
		<!--/.row -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'testimonial2',
	components: {},
	props: {
		p1: String,
		h5_1: String,
		p2: String,
		h5_2: String,
		p3: String,
		h5_3: String,
		p4: String,
		h5_4: String,
		h2: String,
		t1: String,
		t2: String,
		a: String,
		link: String
	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
