<template>
<section>
	<div class="container pt-7 pt-md-8 pb-13 pb-md-15">
		<div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
			<div class="col-lg-4">
				<h2 class="fs-15 text-uppercase text-line text-primary text-center mb-3">{{accroche}}</h2>
				<h3 class="display-5 mb-5">{{titre}}</h3>
				<p>{{text}}</p>
				<a :href="link"
					 class="btn btn-primary   mt-3">{{link_label}}</a>
			</div>
			<!--/column -->
			<div class="col-lg-8">


				<carousel :items=3
									:nav=false>


					<div class="item">
						<img class="rounded-circle w-20 mx-auto mb-4"
								 :src="img1"
								 alt="">
						<h4 class="mb-1">{{nom1}}</h4>
						<div class="meta mb-2">{{metier1}}</div>
						<p class="mb-2">{{present1}}</p>

						<!-- /.social -->
					</div>

					<div class="item">
						<img class="rounded-circle w-20 mx-auto mb-4"
								 :src="img2"
								 alt="">
						<h4 class="mb-1">{{nom2}}</h4>
						<div class="meta mb-2">{{metier2}}</div>
						<p class="mb-2">{{present2}}</p>


						<!-- /.social -->
					</div>

					<div class="item">
						<img class="rounded-circle w-20 mx-auto mb-4"
								 :src="img3"
								 alt="">
						<h4 class="mb-1">{{nom3}}</h4>
						<div class="meta mb-2">{{metier3}}</div>
						<p class="mb-2">{{present3}}</p>
						<!--/column -->
					</div>
					<!--/.row -->
					<div class="item">
						<img class="rounded-circle w-20 mx-auto mb-4"
								 :src="img4"
								 alt="">
						<h4 class="mb-1">{{nom4}}</h4>
						<div class="meta mb-2">{{metier4}}</div>
						<p class="mb-2">{{present4}}</p>
						<!--/column -->
					</div>
					<!--/.row -->
					<div class="item">
						<img class="rounded-circle w-20 mx-auto mb-4"
								 :src="img5"
								 alt="">
						<h4 class="mb-1">{{nom5}}</h4>
						<div class="meta mb-2">{{metier5}}</div>
						<p class="mb-2">{{present5}}</p>
						<!--/column -->
					</div>
					<!--/.row -->

					<!--/.code-wrapper-inner -->
				</carousel>
				<!--/.code-wrapper -->
			</div>
			<!--/.card-footer -->
		</div>
		<!--/.card -->
	</div>
	<!-- /.container -->


</section>
</template>


<script>
import carousel from 'vue-owl-carousel'


export default {
	name: 'team',
	components: {
		carousel
	},
	props: {
		accroche: String,
		titre: String,
		text: String,
		link: String,
		link_label: String,
		img1: String,
		nom1: String,
		metier1: String,
		present1: String,
		img2: String,
		nom2: String,
		metier2: String,
		present2: String,

		img4: String,
		nom4: String,
		metier4: String,
		present4: String,

		img5: String,
		nom5: String,
		metier5: String,
		present5: String,


		img3: String,
		nom3: String,
		metier3: String,
		present3: String
	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
